import CopyComponent from "./CopyComponent";
import { Badge } from "@chakra-ui/react";

export default function FtpBadge({
  children,
  colorScheme,
  icon,
  short,
  size,
  style,
  value,
  title,
  href,
  disableCopy,
  ...props
}) {
  let badgeStyle = {};
  if (size && size === "xs") {
    badgeStyle = { padding: 3, borderRadius: 5, fontSize: "0.6rem" };
  }
  return (
    <Badge
      style={{ ...style, ...badgeStyle }}
      {...props}
      colorScheme={colorScheme}
    >
      {disableCopy && children}
      {!disableCopy && (
        <CopyComponent
          className={`${short ? "short " : ""}ftp-badge`}
          value={children ? children : value}
          title={title || (title !== false ? value : undefined)}
          component={
            <>
              {icon}
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: short ? "50px" : "inherit",
                  marginLeft: icon ? "3px" : "0",
                }}
              >
                {children ? children : value}
              </div>
            </>
          }
        />
      )}
    </Badge>
  );
}
