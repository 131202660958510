import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { Badge, Text, Table, Thead, Tbody, Tr, Th, Td, Tooltip } from '@chakra-ui/react';

const FtmsBreachesTable = ({ data }) => {
  const columnHelper = createColumnHelper();

  const formatCurrency = value => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const formatDuration = (ms) => {
    if (ms === 0) {
      return 'Single tick';
    }

    const milliseconds = ms % 1000;
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s ` : ''}${milliseconds}ms`;
  };

  const columns = [
    columnHelper.accessor('breachedAt', {
      header: 'Breached At',
      cell: info => new Date(info.getValue()).toLocaleString(),
    }),
    columnHelper.accessor('breachDurationMs', {
      header: 'Breach Duration',
      cell: info => formatDuration(info.getValue()),
    }),
    columnHelper.accessor('type', {
      header: 'Flags',
      cell: info => <div>
        {info.row.original.type === 'trailing_drawdown_breached' && <Tooltip label='Breach type: Trailing Drawdown'><Badge colorScheme="red">TD</Badge></Tooltip>}
        {info.row.original.type === 'daily_drawdown_breached' && <Tooltip label='Breach type: Daily Drawdown'><Badge colorScheme="red">DD</Badge></Tooltip>}
        {info.row.original.hasPartialClosures === 1 && <Tooltip label='Potential issue in computation of this breach due inconsistent reporting of "Partial Closures" by FPFX.'><Badge colorScheme="yellow">PC</Badge></Tooltip>}
        {info.row.original.missingSwap === 1 && <Tooltip label='Potential issue in computation of this breach due to missing reporting of swaps for this account by FPFX.'><Badge colorScheme="yellow">SW</Badge></Tooltip>}
      </div>,
    }),
    columnHelper.accessor('balanceAtLastDailyCapture', {
      header: 'Balance LDC',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('currentBalance', {
      header: 'Balance',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('currentEquity', {
      header: 'Equity',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('dailyDrawdown', {
      header: 'DD',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('dailyDrawdownPercent', {
      header: 'DD %',
      cell: info => `${info.getValue()}%`,
    }),
    columnHelper.accessor('highestBalance', {
      header: 'Highest Balance',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('trailingDrawdown', {
      header: 'Trailing Drawdown',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('minEquityDuringBreachDuration', {
      header: 'Min Equity During Breach',
      cell: info => formatCurrency(info.getValue()),
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: info => new Date(info.getValue()).toLocaleString(),
    }),
  ];

  const [sorting, setSorting] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table size="sm" fontSize="xs">
      <Thead
        css={{
          position: "-webkit-sticky",
        }}
        position="sticky"
        top="0"
        zIndex="1"
        bg={"#111D45"}
      >
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
              >
                {header.column.getCanSort() && (
                  <span>

                  </span>
                )}
                <Text>{header.column.getIsSorted()
                  ? header.column.getIsSorted() === 'desc'
                    ? <MdKeyboardArrowUp style={{ display: 'inline' }} />
                    : <MdKeyboardArrowDown style={{ display: 'inline' }} />
                  : ''} {flexRender(header.column.columnDef.header, header.getContext())}</Text>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => (
          <Tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default FtmsBreachesTable;
