export function isPassLocked(pass, timeout, user) {
  if (!user) {
    return true;
  }

  const updatedAt = pass.client?.metadata?.lock?.updatedAt;

  // if the pass is locked by another user, make extra checks
  if (pass.client?.metadata.lock?.user !== user?.id) {
    // updatedAt is converted to local time, so comparison of date objectswill be in local time
    return updatedAt && new Date(updatedAt) > new Date(new Date() - timeout);
  }

  return false;
}
