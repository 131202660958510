import {
  Alert,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { formatter } from "variables/utils";
import FtpBadge from "components/FtpBadge";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const CopyTradingReport = ({ data }) => {
  const { report, account } = data;

  const getRatingColour = (profitPercent) => {
    if (Math.abs(profitPercent) <= 0.5) {
      return "green";
    } else if (Math.abs(profitPercent) <= 2) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const columnsPotentialPartners = React.useMemo(
    () => [
      {
        accessorKey: "partnerLogin",
        header: "Trade Account",
        title: "Trade Account",
        cell: ({ row, getValue }) => (
          <FtpBadge
            title="Account Login"
            short
            colorScheme={getRatingColour(row.original.profitPercent)}
          >
            {getValue()}
          </FtpBadge>
        ),
      },
      {
        accessorKey: "profit",
        header: "Profit",
        title: "Profit",
        cell: ({ getValue }) => formatter.format(getValue()),
      },
      {
        accessorKey: "profitPercent",
        header: "%",
        title: "%",
        cell: ({ getValue }) => `${Math.abs(getValue())}%`,
      },
      {
        accessorKey: "count",
        header: "Count",
        title: "Count",
      },
    ],
    []
  );

  const columnsSimilarTrades = React.useMemo(
    () => [
      {
        accessorKey: "ticket",
        header: "Ticket",
        title: "Ticket",
        cell: ({ getValue }) => <FtpBadge value={getValue()} short></FtpBadge>,
      },
      {
        accessorKey: "profitFromOriginal",
        header: "Profit",
        title: "Profit",
        cell: ({ getValue }) => formatter.format(getValue()),
      },
      {
        accessorKey: "similar",
        header: "Type",
        title: "Type",
        cell: ({ getValue }) => (
          <Stack direction="row" wrap gap={1}>
            {getValue().map((trade) => (
              <FtpBadge
                key={trade.trade}
                value={trade.type}
                short
                colorScheme="green"
                variant="outline"
              ></FtpBadge>
            ))}
          </Stack>
        ),
      },
      {
        accessorKey: "similar",
        header: "Similar Ticket",
        title: "Similar Ticket",
        cell: ({ getValue }) => (
          <Stack direction="row" wrap gap={1}>
            {getValue().map((trade) => (
              <FtpBadge key={trade.trade} short>
                {trade.ticket}
              </FtpBadge>
            ))}
          </Stack>
        ),
      },
      {
        accessorKey: "similar",
        header: "Login",
        title: "Login",
        cell: ({ getValue }) => (
          <Stack direction="row" wrap gap={1}>
            {getValue().map((trade) => (
              <Stack key={trade.trade} direction="row" wrap>
                <FtpBadge key={trade.trade} short>
                  {trade.partnerLogin}
                </FtpBadge>
              </Stack>
            ))}
          </Stack>
        ),
      },
      {
        accessorKey: "similar",
        header: "Open Time Diff.",
        title: "Open Time Diff.",
        title: "Open Time Difference",
        cell: ({ getValue }) => (
          <Stack direction="row" wrap gap={1}>
            {getValue().map((trade) => (
              <FtpBadge
                key={trade.trade}
                style={{ textTransform: "lowercase" }}
                short
                disableCopy
              >
                {trade.openDifferenceSeconds}s
              </FtpBadge>
            ))}
          </Stack>
        ),
      },
      {
        accessorKey: "similar",
        header: "Close Time Diff.",
        title: "Close Time Difference",
        cell: ({ getValue }) => (
          <Stack direction="row" wrap gap={1}>
            {getValue().map((trade, idx) => (
              <FtpBadge
                key={trade.trade}
                short
                disableCopy
                style={{ textTransform: "lowercase" }}
              >
                {trade.closeDifferenceSeconds}s
              </FtpBadge>
            ))}
          </Stack>
        ),
      },
    ],
    []
  );

  const potentialPartners = React.useMemo(
    () =>
      report?.deepDive?.potentialPartners
        ? Object.keys(report.deepDive.potentialPartners).map((key) => ({
            number: key,
            ...report.deepDive.potentialPartners[key],
          }))
        : [],
    [report]
  );

  const similarTrades = React.useMemo(
    () =>
      report?.deepDive?.similarTrades
        ? Object.keys(report.deepDive.similarTrades).map((key) => ({
            number: key,
            ...report.deepDive.similarTrades[key],
          }))
        : [],
    [report]
  );

  const TableComponent = ({ columns, data }) => {
    const [sorting, setSorting] = React.useState([]);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    return (
      <Table size="sm">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  title={
                    header.column.columnDef.title ??
                    header.column.columnDef.header
                  }
                >
                  {{
                    asc: <MdKeyboardArrowUp style={{ display: "inline" }} />,
                    desc: <MdKeyboardArrowDown style={{ display: "inline" }} />,
                  }[header.column.getIsSorted()] ?? null}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.length === 0 ? (
            <Tr>
              <Td colSpan={columns.length} style={{ textAlign: "center" }}>
                <Text>No records to Display</Text>
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <Tr>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      borderBottomWidth={row.getIsExpanded() ? "0" : "1px"}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              </Fragment>
            ))
          )}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
    );
  };

  if (!report || !account) {
    return (
      <Alert status="warning">No report exists for this trading account</Alert>
    );
  }

  return (
    <Tabs>
      <TabList>
        <Tab py={0}>User accounts</Tab>
        <Tab py={0}>Potential Partners</Tab>
        <Tab py={0}>Similar Trades</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <TableComponent
            columns={columnsPotentialPartners}
            data={potentialPartners.filter(
              (partner) => partner.ownAccount && partner.number !== account.id
            )}
          />
        </TabPanel>
        <TabPanel>
          <TableComponent
            columns={columnsPotentialPartners}
            data={potentialPartners.filter((partner) => !partner.ownAccount)}
          />
        </TabPanel>
        <TabPanel>
          <TableComponent columns={columnsSimilarTrades} data={similarTrades} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CopyTradingReport;
