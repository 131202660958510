// Chakra imports
import { Button, Select, Heading, HStack, VStack } from "@chakra-ui/react";
import validator from "validator";
import { toast } from "react-toastify";
import { accountTypes, purposeTypes } from "../constant";
import { ALERT_MESSAGE } from "variables/message";

export default function SecondForm(props) {
  const { preStep, nextStep, formData, setFormData } = props;
  const toNext = () => {
    if (validator.isEmpty(formData.accountType)) {
      toast.error(ALERT_MESSAGE.PAYOUT_REQUIRE_ACCOUNT_TYPE);
    } else {
      nextStep();
    }
  };
  // check if formData.payoutOptions contains bankIndividual in array
  // if not formData or empty formData.payoutOptions, then true
  const hasBankIndividual =
    !formData.payoutOptions?.length ||
    formData.payoutOptions?.includes(accountTypes.BANK_INDIVIDUAL.value);
  const hasBankCompany =
    !formData.payoutOptions?.length ||
    formData.payoutOptions?.includes(accountTypes.BANK_COMPANY.value);
  const hasCrypto =
    !formData.payoutOptions?.length ||
    formData.payoutOptions?.includes(accountTypes.CRYPTOCURRENCY_WALLET.value);

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Payout Information Form</Heading>

      <Select
        id="account_type"
        placeholder="Account Type"
        onChange={(e) => {
          setFormData({ ...formData, accountType: e.target.value });
        }}
        value={formData.accountType}
      >
        {hasBankIndividual && (
          <option value={accountTypes.BANK_INDIVIDUAL.value}>
            {accountTypes.BANK_INDIVIDUAL.label}
          </option>
        )}

        {hasBankCompany && (
          <option value={accountTypes.BANK_COMPANY.value}>
            {accountTypes.BANK_COMPANY.label}
          </option>
        )}

        {purposeTypes.IFXTEST !== formData.purpose && hasCrypto && (
          <option value={accountTypes.CRYPTOCURRENCY_WALLET.value}>
            {accountTypes.CRYPTOCURRENCY_WALLET.label}
          </option>
        )}
      </Select>
      <HStack spacing={4} justifyContent="space-between">
        <Button variant="ghost" onClick={preStep}>
          Back
        </Button>
        <Button colorScheme="green" onClick={toNext}>
          Next
        </Button>
      </HStack>
    </VStack>
  );
}
