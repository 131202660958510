import {
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import { PassTable } from "./components/PassTable";
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

import { MdSearch } from "react-icons/md";
import Pagination from "components/pagination/pagination";
import CheckboxMenu from "components/CheckboxMenu";

export default function Passes() {
  const [statusFilter, setStatusFilter] = useState(["new", "inProgress"]);
  const [showLocked, setShowLocked] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const debouncedSetStatusFilter = debounce(setStatusFilter, 500);
  const debouncedSetFilter = debounce(setFilter, 500);

  const fetchPasses =
    (statusFilter) =>
      async ({ queryKey, ...rest }) => {
        let queryUrl = `/api/passes?page=${page}&limit=${pageSize}&sortBy={"pass.passedAt":"asc"}&populate=client&populate=report&populate=ftmsBreaches&populate=metadata.parentAccount>ftmsBreaches&populate=client.accountStats`;
        if (statusFilter.length) {
          queryUrl += statusFilter.map((status) => `&status=${status}`).join("");
        }
        if (filter) {
          queryUrl += `&filter=${filter}`;
        }
        if (showLocked) {
          queryUrl += `&showLocked=1`;
        }

        const result = await Client(true).get(queryUrl);
        return result.data;
      };

  const { data, isLoading } = useQuery({
    queryKey: [
      "passes",
      statusFilter.join(","),
      filter,
      page,
      pageSize,
      showLocked,
    ],
    queryFn: fetchPasses(statusFilter),
    refetchInterval: 10000,
    refetchOnMount: true,
    keepPreviousData: true,
    initialData: { data: { docs: [] } },
  });

  useEffect(() => {
    if (data && page > data.totalPages) {
      setPage(1);
    }
  }, [data, page]);

  return (
    <TableContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100vh - 50px)",
        gap: "1rem",
      }}
      overflowY={"none"}
      overflowX={"none"}
    >
      <HStack justify="space-evenly" gap="10px">
        <Flex flexGrow={1}>
          <FormControl>
            <InputGroup
              border={"1px solid"}
              borderColor={"#0A1129"}
              rounded={"lg"}
              bg={"#111D45"}
            >
              <InputLeftElement>
                <MdSearch color="gray.500" />
              </InputLeftElement>
              <Input
                color={"white"}
                placeholder="Enter Client ID, TradeAccount ID or email"
                onChange={(e) => {
                  debouncedSetFilter(e.target.value);
                }}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex gap="10px" alignItems={"center"}>
          <CheckboxMenu
            indicateSelection={true}
            onChange={(value) => {
              debouncedSetStatusFilter(value);
            }}
            selectedValues={statusFilter}
            items={[
              { value: "manual", title: "Manual" },
              { value: "new", title: "New" },
              { value: "inProgress", title: "In Progress" },
              { value: "completed", title: "Completed" },
              { value: "hold", title: "Hold" },
              { value: "denied", title: "Denied" },
            ]}
          >
            Status Filter
          </CheckboxMenu>
          <FormControl>
            <Checkbox
              id="showlocked"
              key="showlocked"
              value="1"
              variant="button"
              onChange={(value) => {
                setShowLocked(value.target.checked);
              }}
            >
              Show Locked
            </Checkbox>
          </FormControl>
        </Flex>
      </HStack>

      <PassTable
        style={{ flexGrow: 1 }}
        data={data.docs ? data.docs : []}
        loading={isLoading}
      />

      <Flex>
        {data.docs && (
          <Pagination
            setPage={setPage}
            data={{ data }}
            setPageSize={setPageSize}
          />
        )}
      </Flex>
    </TableContainer>
  );
}
