import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import ActionButton from "./ActionButton";

export const jsonToCsvTransformer = (json) => {
  const header = Object.keys(json[0]).join(",");
  const rows = json
    .map((obj) =>
      Object.values(obj)
        .map((val) => (typeof val === "string" ? `"${val}"` : val))
        .join(",")
    )
    .join("\n");
  return `${header}\n${rows}`;
};

export const CsvDownloadButton = ({
  url,
  fileName,
  children,
  transformer,
  type = "text/csv",
  ...params
}) => {
  const { refetch, isFetching } = useQuery({
    queryKey: [url],
    queryFn: async () => {
      console.log("Fetching data from", url);
      const response = await Client(true).get(url);
      return response.data;
    },
    enabled: false, // Disable automatic fetching
  });

  const handleDownload = async () => {
    try {
      const result = await refetch();
      if (result.data) {
        console.log("Successfully fetched data", result.data);

        const content = transformer ? transformer(result.data) : result.data;

        // Create a Blob object from the content
        const csvBlob = new Blob([content], { type });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(csvBlob);

        // Create an anchor element and trigger download
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        URL.revokeObjectURL(blobUrl);
        downloadLink.remove();
      }
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  return (
    <ActionButton onClick={handleDownload} {...params} isLoading={isFetching}>
      {children}
    </ActionButton>
  );
};