// Chakra imports
import {
  Button,
  Select,
  Input,
  InputGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  VStack,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { MdCardTravel, MdOutlineLocationOn } from "react-icons/md";
import { accountTypes } from "../constant";
import { FormInput } from "components/FormInput";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import React from "react";

import countries from "assets/json/countries.json";

export default function ThirdForm(props) {
  const { preStep, nextStep, next2Step, formData, setFormData } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur", defaultValues: formData });

  function onSubmit(values) {
    setFormData({ ...formData, ...values });
    if (formData.accountType === accountTypes.CRYPTOCURRENCY_WALLET.value) {
      next2Step();
    } else {
      nextStep();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack align="stretch">
        <Heading size="md">Payout Information Form</Heading>
        <FormInput
          id="firstName"
          label="First name"
          icon={<MdCardTravel color="gray.300" />}
          rules={{
            required: "This is required",
          }}
          control={control}
          variant="floating"
        />

        {formData.accountType === accountTypes.BANK_INDIVIDUAL.value && (
          <FormInput
            id="lastName"
            label="Legal Last Name"
            icon={<MdCardTravel color="gray.300" />}
            rules={{
              required: "This is required",
            }}
            control={control}
            variant="floating"
          />
        )}

        {formData.accountType === accountTypes.CRYPTOCURRENCY_WALLET.value && (
          <FormControl isInvalid={errors.phone} variant="floating">
            <InputGroup>
              <PhoneInputWithCountry
                name="phone"
                placeholder="International Phone Number"
                control={control}
                shouldUnregister={true}
                rules={{ required: "This is required" }}
                style={{ width: "100%" }}
                international={true}
                inputComponent={React.forwardRef(
                  ({ value, onChange, onFocus, onBlur, ...args }, ref) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      ref={ref}
                      {...args}
                    />
                  )
                )}
              />
            </InputGroup>
            <FormHelperText textAlign="initial">
              {!errors.phone &&
                "Please provide a valid phone number where we can send your one-time password (OTP)."}
            </FormHelperText>
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>
        )}

        <FormInput
          id="street"
          label="Street Address"
          icon={<MdOutlineLocationOn color="gray.300" />}
          rules={{
            required: "This is required",
          }}
          control={control}
          variant="floating"
        />

        <FormInput
          id="city"
          label="City"
          icon={<MdOutlineLocationOn color="gray.300" />}
          rules={{
            required: "This is required",
          }}
          control={control}
          variant="floating"
        />

        <FormInput
          id="region"
          label="Region/State"
          icon={<MdOutlineLocationOn color="gray.300" />}
          rules={{
            required: "This is required",
          }}
          control={control}
          variant="floating"
        />

        <FormInput
          id="zipCode"
          label="Postal/Zip Code"
          icon={<MdOutlineLocationOn color="gray.300" />}
          rules={{
            required: "This is required",
          }}
          control={control}
          variant="floating"
        />

        <FormControl isInvalid={errors.country} variant="floating">
          <InputGroup>
            <Select
              id="country"
              {...register("country", { required: "This is required" })}
            >
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
          </InputGroup>
          <FormLabel>Country</FormLabel>
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>

        <HStack spacing={4} justify="space-between">
          <Button variant="ghost" onClick={preStep}>
            Back
          </Button>
          <Button type="submit" colorScheme={"green"}>
            Next
          </Button>
        </HStack>
      </VStack>
    </form>
  );
}
