import {
  Button,
  Select,
  InputGroup,
  Heading,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Flex,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Card from "components/card/Card.js";
import {
  MdCardTravel,
  MdOutlineLocationOn,
  MdEmail,
  MdAlternateEmail,
  MdLocationCity,
  MdAttachMoney,
} from "react-icons/md";
import { FormInput } from "components/FormInput";

import React from "react";
import countries from "assets/json/countries.json";

export default function OrganisationSettingsForm({ config, saveConfig }) {
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    control,
  } = useForm({ mode: "onBlur", defaultValues: config });
  function onSubmit(values) {
    saveConfig(values);
  }
  return (
    <Flex
      mt={4}
      alignItems="start"
      justifyContent="center"
      flexDirection="column"
    >
      <Card>
        <form id="businessForm" onSubmit={handleSubmit(onSubmit)}>
          <HStack align="stretch">
            <VStack spacing={4} align="stretch" width="50%">
              <Heading size="sm">Business Settings</Heading>
              <FormInput
                id="business.name"
                label="Business Name"
                icon={<MdCardTravel color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormInput
                id="business.address.street"
                label="Street Address"
                icon={<MdOutlineLocationOn color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormInput
                id="business.address.town"
                label="City"
                icon={<MdLocationCity color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormInput
                id="business.address.state"
                label="Region/State"
                icon={<MdOutlineLocationOn color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormInput
                id="business.address.postCode"
                label="Postal/Zip Code"
                icon={<MdOutlineLocationOn color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormControl isInvalid={errors.country} variant="floating">
                <InputGroup>
                  <Select
                    placeholder="Country"
                    {...register("business.address.country", {
                      required: "This is required",
                    })}
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <FormLabel>Country</FormLabel>
                <FormErrorMessage>
                  {errors.country && errors.country.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack spacing={4} align="stretch" width="50%">
              <Heading size="sm">Email Settings</Heading>
              <FormInput
                id="business.opsEmail"
                label="Ops Email"
                icon={<MdEmail color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />
              <FormInput
                id="business.fromEmail"
                label="From Email"
                icon={<MdAlternateEmail color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />

              <FormInput
                id="business.replyToEmail"
                label="Reply-to Email"
                icon={<MdAlternateEmail color="gray.300" />}
                rules={{
                  required: "This is required",
                }}
                control={control}
                variant="floating"
              />
              <Heading size="sm">CRM</Heading>
              <FormInput
                id="crm.clientLockTimeoutMs"
                label="Client lock timeout (ms)"
                control={control}
                type="number"
                variant="floating"
              />
              <FormInput
                id="crm.payoutReviewThreshold"
                label="Payout review threshold"
                icon={<MdAttachMoney color="gray.300" />}
                control={control}
                type="number"
                variant="floating"
              />
              <Heading size="sm">FPFX</Heading>
              <FormInput
                id="fpfx.portalUrl"
                label="Portal URL"
                control={control}
                type="string"
                variant="floating"
              />
            </VStack>
          </HStack>
          <Divider mt={4} />
          <Flex justify={"center"}>
            <Button
              type="submit"
              w="100%"
              colorScheme={"green"}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
}
