import {
  Table,
  Tbody,
  Tfoot,
  Th,
  Thead,
  Tr,
  Td,
  Badge,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  VStack,
  Skeleton,
  Button,
  Box,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, useCallback, useState } from "react";
import {
  MdPreview,
  MdDescription,
  MdPerson,
  MdWaterfallChart,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdLockClock,
} from "react-icons/md";
import { MetaDataStatusBadge } from "./MetaDataStatusBadge";
import { ExpandedRow, currencyFormatNoFracOptions } from "./ExpandedRow";
import { Client } from "api/axios";
import { StatusBadge } from "./StatusBadge";
import DateTime from "components/DateTime";
import { formatMoney } from "variables/utils";
import { useSettings } from "contexts/SettingsProvider";
import AccountAbstract from "components/AccountAbstract";
import { useAuth } from "contexts/AuthProvider";
import { isPassLocked } from "utils/lockCheck";

const columnHelper = createColumnHelper();

export const passStatusToBadgeColour = {
  new: "purple",
  hold: "orange",
  inProgress: "yellow",
  denied: "red",
  completed: "green",
};

export function PassTable({ data, loading, ...props }) {
  const settings = useSettings();
  const contractView = useDisclosure();
  const { auth } = useAuth();

  const [contractHtml, setContractHtml] = useState();
  const getContractPreview = async (id) => {
    const client = Client(true);
    const { data } = await client.get(`/api/client/${id}/contract`);
    return data;
  };

  const openContractView = useCallback(
    async (clientId) => {
      setContractHtml(null);
      contractView.onOpen();
      const preview = await getContractPreview(clientId);
      setContractHtml(preview);
    },
    [setContractHtml, contractView]
  );

  const getContractStatusBadge = useCallback(
    (client) => {
      const hasContract = false; //client.metadata?.contracted?.source?.toLowerCase() !== "manual";
      return (
        <MetaDataStatusBadge
          topicName={"Contract"}
          topicIcon={<MdDescription />}
          metaData={client.metadata?.contracted}
          onClickIcon={hasContract ? <MdPreview /> : undefined}
          onClick={async () => {
            if (hasContract) {
              openContractView(client.id);
            }
          }}
        />
      );
    },
    [openContractView]
  );

  const getAccounts = useCallback((account) => {
    return (
      <VStack>
        <AccountAbstract account={account} />
        {account.metadata?.parentAccount && (
          <AccountAbstract account={account.metadata.parentAccount} />
        )}
      </VStack>
    );
  }, []);

  const columns = [
    {
      id: "expander",
      header: () => loading && <Spinner size="xs" />,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <Button
            size="xs"
            p={"2px !important"}
            onClick={row.getToggleExpandedHandler()}
            cursor="pointer"
          >
            {row.getIsExpanded() ? (
              <MdKeyboardArrowDown />
            ) : (
              <MdKeyboardArrowRight />
            )}
          </Button>
        ) : (
          ""
        );
      },
    },

    columnHelper.accessor("pass.status", {
      header: "Status",
      cell: ({ row }) => {
        const isLocked = isPassLocked(
          row.original,
          settings?.publicSettings.crm?.clientLockTimeoutMs || 300000,
          auth.user
        );
        return row.original ? (
          <VStack align="start" justify="center" gap="0">
            <Badge
              colorScheme={passStatusToBadgeColour[row.original.pass.status]}
            >
              {row.original.pass.status}
            </Badge>
            {isLocked && (
              <Badge colorScheme="red">
                <MdLockClock />
              </Badge>
            )}
          </VStack>
        ) : (
          row.groupByVal
        );
      },
    }),
    columnHelper.accessor("pass.passedAt", {
      header: "Passed At",

      cell: ({ row }) =>
        row.original && row.original.pass?.passedAt ? (
          <DateTime isoString={row.original.pass.passedAt} />
        ) : (
          ""
        ),
    }),
    columnHelper.accessor("client", {
      header: "Client Details",
      cell: ({ row }) => {
        let totalPayoutsText = "";
        if (row.original.client?.metadata?.totalPayouts) {
          totalPayoutsText = `Payouts: ${formatMoney(
            row.original.client.metadata.totalPayouts,
            0
          )}`;
        }

        return row.original ? (
          <VStack align="start" justify="center" gap={0}>
            <Text
              as="b"
              noOfLines={1}
              maxWidth="15em"
              display={"unset"}
              title={row.original.client?.name}
            >
              {row.original.client?.name}
            </Text>
            <Text
              as="b"
              noOfLines={1}
              maxWidth="15em"
              display={"unset"}
              title={row.original.client?.email}
            >
              {row.original.client?.email}
            </Text>
            <Text>{totalPayoutsText}</Text>
          </VStack>
        ) : (
          row.groupByVal
        );
      },
    }),
    columnHelper.accessor("id", {
      header: "Account",
      cell: ({ row }) =>
        row.original ? getAccounts(row.original) : row.groupByVal,
    }),
    columnHelper.accessor("plan", {
      header: "Plan",
      cell: ({ row }) =>
        row.original ? (
          <VStack align="start" justify="center" gap="0">
            <Text>
              {(row.original.plan
                ? row.original.plan?.startingBalance
                : row.original.startingBalance
              )?.toLocaleString("en-US", currencyFormatNoFracOptions)}
            </Text>
            <Text
              title={
                row.original.plan
                  ? row.original.plan?.description
                  : row.original.description
              }
              noOfLines={2}
              maxWidth="15em"
              display={"unset"}
            >
              {row.original.plan
                ? row.original.plan?.description
                : row.original.description}
            </Text>
          </VStack>
        ) : (
          row.groupByVal
        ),
    }),
    columnHelper.accessor("pass.stats.daysToPass", {
      header: "Days",
      cell: (info) => info.getValue() | "",
    }),
    columnHelper.accessor("pass.stats.totalTrades", {
      header: "Trades",
      cell: (info) => info.getValue() | "",
    }),
    columnHelper.accessor("pass.stats.winRate", {
      header: "Win %",
      cell: (info) =>
        info.getValue()?.toLocaleString("en-GB", { style: "percent" }),
    }),
    columnHelper.accessor("client.accountStats.funded", {
      header: "Ever funded",
      cell: (info) => info.getValue() > 0 ? "Yes" : "No",
    }),
    columnHelper.accessor("client", {
      header: "Progress",
      cell: ({ row }) => {
        const kycMeta = row.original.client?.metadata?.kyc;
        const kycLink =
          kycMeta?.source && kycMeta?.source !== "manual"
            ? `https://cockpit.sumsub.com/checkus#/applicant/${kycMeta.source}/client/basicInfo`
            : undefined;
        return (
          <Box style={{ gap: 1, display: "flex", flexDirection: "column" }}>
            <MetaDataStatusBadge
              topicName={"KYC"}
              topicIcon={<MdPerson />}
              metaData={kycMeta}
              linkOut={kycLink}
            />
            {getContractStatusBadge(row.original.client)}
            <StatusBadge
              color={row.original.metadata?.childAccount ? "green" : "purple"}
              topicIcon={<MdWaterfallChart />}
              topicName={"Live Account"}
              statusText={
                row.original.metadata?.childAccount ? "Issued" : "Pending"
              }
            />
          </Box>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Skeleton
      rounded={"xl"}
      isLoaded={!loading}
      overflowY={"scroll"}
      bg={"#111D45"}
      {...props}
    >
      <Table size="sm">
        <Thead
          css={{
            position: "-webkit-sticky",
          }}
          position="sticky"
          top="0"
          zIndex="1"
          bg={"#111D45"}
        >
          {table.getHeaderGroups().map((headerGroup, hIdx) => (
            <Tr key={`${headerGroup.id}-h-${hIdx}`}>
              {headerGroup.headers.map((header, hhIdx) => (
                <Th key={`${header.id}-h-${hhIdx}`} p={"10px"}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={`${row.id}-row`}>
              <Tr>
                {row.getVisibleCells().map((cell, idx) => (
                  <Td
                    key={`${cell.id}_${idx}`}
                    borderBottomWidth={row.getIsExpanded() ? "0" : "1px"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
              {row.getIsExpanded() && (
                <Tr key={`${row.id}-exp`}>
                  {/* 2nd row is a custom 1 cell row */}
                  <Td
                    borderBottomWidth={row.getIsExpanded() ? "1px" : "0"}
                    borderColor={
                      row.getIsExpanded()
                        ? "var(--chakra-colors-whiteAlpha-300)"
                        : "0"
                    }
                    colSpan={row.getVisibleCells().length}
                    style={{ overflow: "hidden" }}
                  >
                    <ExpandedRow row={row} />
                  </Td>
                </Tr>
              )}
            </Fragment>
          ))}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map((footerGroup, fIdx) => (
            <Tr key={`${footerGroup.id}_${fIdx}`}>
              {footerGroup.headers.map((header, fhIdx) => (
                <Th key={`${header.id}_${fhIdx}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
      <Modal
        isOpen={contractView.isOpen}
        onClose={contractView.onClose}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contract</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" alignContent="center">
            {!contractHtml && <Spinner />}
            {contractHtml && (
              <iframe
                title="contract"
                srcDoc={contractHtml}
                style={{ width: "100%", backgroundColor: "#fff" }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Skeleton>
  );
}
