import {
  Button,
  ModalOverlay,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalContent,
  useColorModeValue,
  Radio,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { FormInput } from "components/FormInput";
import { RadioGroupInput } from "./RadioGroupInput";
import { useForm } from "react-hook-form";

export const ModalManualKyc = ({
  isOpen,
  onClose,
  onConfirm,
  firstNameEn,
  lastNameEn,
  applicantId,
}) => {
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  const inputStyle = {
    variant: "filled",
    fontSize: "sm",
    type: "text",
    bg: inputBg,
    color: inputText,
    fontWeight: "500",
    _placeholder: { color: "gray.400", fontSize: "14px" },
    borderRadius: "10px",
  };

  const {
    handleSubmit,
    formState: { isValid },
    watch,
    control,
  } = useForm({ mode: "onBlur" });

  const type = watch("type");

  const onSubmit = (values) => {
    if (type === "kycDetails") {
      values.firstNameEn = values.firstNameEn.trim();
      values.lastNameEn = values.lastNameEn.trim();
      delete values.applicantId;
    } else {
      values.applicantId = values.applicantId.trim();
      delete values.firstNameEn;
      delete values.lastNameEn;
    }
    onConfirm(values);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Manual KYC</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={1}>
              <Text>
                Please select the type of manual KYC you would like to perform.
              </Text>
              <RadioGroupInput id="type" control={control}>
                <Stack direction="row">
                  <Radio value="kycDetails">KYC Details</Radio>
                  <Radio value="kycApplicantId">KYC Applicant Id</Radio>
                </Stack>
              </RadioGroupInput>
            </Stack>
            {type === "kycDetails" && (
              <Stack spacing={1}>
                <Text marginTop="10px">
                  Please provide the KYC details for this client.
                </Text>
                <FormInput
                  id="firstNameEn"
                  placeholder="First name"
                  rules={{ required: "This is required." }}
                  control={control}
                  inputStyle={inputStyle}
                  defaultValue={firstNameEn}
                />
                <FormInput
                  id="lastNameEn"
                  placeholder="Last name"
                  rules={{ required: "This is required." }}
                  control={control}
                  inputStyle={inputStyle}
                  defaultValue={lastNameEn}
                />
              </Stack>
            )}
            {type === "kycApplicantId" && (
              <Stack spacing={1}>
                <Text marginTop="10px">
                  Please provide the sumsub applicant id for this client.
                </Text>
                <FormInput
                  id="applicantId"
                  placeholder="ApplicantId"
                  rules={{ required: "This is required." }}
                  control={control}
                  inputStyle={inputStyle}
                  defaultValue={applicantId}
                />
              </Stack>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              type="submit"
              disabled={!isValid}
            >
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
